import {isRegistrationClosedVisible, getExternalLink, isTicketed, isExternal} from '@wix/wix-events-commons-statics'
import {isSecondRegistrationButtonVisible} from '../../../selectors/event'
import {isMemberRsvpExists} from '../../../selectors/member-rsvp'
import {
  getButtonStyle,
  getTexts,
  isGroupVisibilityEnabled,
  isRsvpButtonVisibilityEnabled,
} from '../../../selectors/settings'
import {DetailsPageAppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/context'
import {SecondaryRsvpButtonOwnProps, SecondaryRsvpButtonRuntimeProps} from './interfaces'
import {SecondaryRsvpButton as Presentation} from './secondary-rsvp-button'

const mapRuntime = ({state}: DetailsPageAppProps): SecondaryRsvpButtonRuntimeProps => {
  const event = state.event
  const componentSettings = state.component.settings
  const visible = isSecondRegistrationButtonVisible(state)

  return {
    visible,
    rsvpButtonText: getTexts(componentSettings).rsvpButtonText,
    buttonStyle: getButtonStyle(componentSettings),
    url: getExternalLink(event),
    isExternalLink: isExternal(event),
  }
}

export const SecondaryRsvpButton = connect<SecondaryRsvpButtonOwnProps, SecondaryRsvpButtonRuntimeProps>(mapRuntime)(
  Presentation,
)

export * from './interfaces'
