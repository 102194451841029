import React from 'react'
import classNames from 'classnames'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {RegistrationButtonSkin} from '../../registration-buttons/registration-button-skin'
import s from './secondary-rsvp-button.scss'
import {SecondaryRsvpButtonProps} from './index'

export const SecondaryRsvpButton = ({
  rsvpButtonText,
  buttonStyle,
  onClick,
  className,
  url,
  isExternalLink,
  visible,
}: SecondaryRsvpButtonProps) => {
  if (!visible) {
    return null
  }

  return (
    <div className={classNames(s.spacing, className)}>
      <RegistrationButtonSkin
        buttonStyle={buttonStyle}
        text={rsvpButtonText}
        onClick={onClick}
        url={url}
        isExternalLink={isExternalLink}
        dataHook={DH.RSVP_BUTTON}
      />
    </div>
  )
}
